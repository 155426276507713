import {
  extendTheme,
  withDefaultColorScheme,
  theme as baseTheme,
} from '@chakra-ui/react'

import '@fontsource/rubik'

import '@fontsource/rubik/400.css'
import '@fontsource/rubik/500.css'
import '@fontsource/rubik/600.css'
import '@fontsource/rubik/700.css'



const theme = extendTheme(
  {
    colors: {
      brand: baseTheme.colors.blue,
      'blue1': "#234395",
      'blue2': "#799BF3",
      'organge1': "#FF7919"
    },
    fonts: {
      heading: `'Rubik', sans-serif`,
      body: `'Rubik', sans-serif`,
    },
    components: {
      Button: {
        sizes: {
          xl: {
            h: '56px',
            fontSize: 'xl',
            px: '32px',
          },
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: 'brand' })
)

export default theme
